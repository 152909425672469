<ui-toast></ui-toast>

<div class="main">
  <app-menu class="main-menu"></app-menu>

  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
  
  <app-ui-bottom></app-ui-bottom>
</div>

