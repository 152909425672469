<ng-template #menuTemplate>
  @if (user) {
  <div cdkMenu class="menu">
    <div cdkMenuItem class="user-name not-clickable">
      <span>{{ user.displayName || '' }}</span>
    </div>

    <div cdkMenuItem class="user-avatar not-clickable">
      <div class="user-avatar-container">
        @if (user.image) {
        <img class="user-avatar-img" [src]="user.image" />
        } @else {
        <span class="user-avatar-initials">{{ (user.displayName || '') | initials }}</span>
        }
        <button type="button" class="user-avatar-edit">
          <ui-inline-svg class="menu-item-icon" [name]="'edit2'"></ui-inline-svg>
        </button>
      </div>
    </div>

    <div
      cdkMenuItem
      class="menu-item menu-language-list"
      [style.--languages-number]="languagesLength"
      [class.is-open]="languageMenuOpen"
      (click)="onChangeLanguageOpen()">
      <ui-inline-svg class="menu-item-icon" [name]="'language'"></ui-inline-svg>
      @if (languageService.language$ | async; as currentLanguage) {
      <ul class="menu-language-list-container">
        @for (language of availableLanguages; track $index) {
        <li
          class="menu-language-list-item"
          [class.active]="language === currentLanguage"
          (click)="onChangeLanguage(language)">
          {{ 'LANGUAGES.' + language.toUpperCase() | transloco }}
        </li>
        }
      </ul>
      }
    </div>

    <div cdkMenuItem class="menu-item">
      <ui-inline-svg class="menu-item-icon" [name]="'key'"></ui-inline-svg>
      <span>{{ 'MENU.RESET_PASSWORD' | transloco }}</span>
    </div>

    <div cdkMenuItem class="menu-item">
      <ui-inline-svg class="menu-item-icon" [name]="'delete'"></ui-inline-svg>
      <span>{{ 'MENU.TRASH_FILES' | transloco }} (12)</span>
    </div>

    <div cdkMenuItem class="menu-item" (click)="logout()">
      <ui-inline-svg class="menu-item-icon" [name]="'log-out'"></ui-inline-svg>
      <span>{{ 'MENU.LOGOUT' | transloco }}</span>
    </div>
  </div>
  }
</ng-template>
