import { bootstrapApplication } from '@angular/platform-browser';

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { ENVIRONMENT } from '@instaclause-webapp/shared-core';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .then((appRef) => {
    const env = appRef.injector.get(ENVIRONMENT);
    const version = env.version;
    if (version) {
      console.log(`App version: ${version}`);
    }

    amplitude.add(sessionReplayPlugin());
    amplitude.init(env.amplitudeApiKey, { autocapture: true });
  })
  .catch((err) =>
    console.error(err),
  );
