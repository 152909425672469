import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';


import { PageService } from '@instaclause-webapp/shared-core';
import { ButtonComponent, ButtonColors } from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

import { LanguageComponent } from '../language/language.component';

@Component({
  selector: 'app-ui-bottom',
  standalone: true,
  imports: [ CommonModule, RouterModule, TranslocoModule, ButtonComponent, LanguageComponent ],
  templateUrl: './bottom.component.html',
  styleUrl: './bottom.component.scss',
})
export class BottomComponent implements OnInit, OnDestroy {
  isHome = false;
  currentRoute!: string;
  buttonColor = ButtonColors;

  private subscription = new Subscription();

  constructor(
    private pageService: PageService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url;

    this.subscription = this.pageService.currentPath$.subscribe((url) => {
      this.isHome = url === '/contracts';
      this.currentRoute = url;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.location.back();
  }

  createContract() {
    console.log('create contract');
  }

  onDeleteContract(contractId: string) {
    console.log('delete contract', contractId);
  }
}
