<div class="menu">
  <div class="menu-container">
    <!-- Breadcrumbs -->
    @if (user) {
    <nav class="breadcrumb">
      <ul class="breadcrumb-list">
        @for (item of breadcrumb(); track $index){
        <li class="breadcrumb-list-item">
          <a
            [routerLink]="item.path"
            class="breadcrumb-list-item-link"
            [class.disabled]="item.path === currentPage()">
            <ui-inline-svg [name]="item.icon"></ui-inline-svg>
            <span>{{ item.title | transloco }}</span>
          </a>
        </li>
        }
      </ul>
    </nav>
    } @else {
    {{ 'MENU.LOGIN' | transloco }}
    }

    <!-- Logo -->
    <ui-logo></ui-logo>

    @if (user) {
    <div class="actions">
      <div class="navigation">
        <a
          uiTooltip
          [tooltipText]="'MENU.CONTRACTS' | transloco"
          [tooltipPosition]="'bottom'"
          [routerLink]="['contracts']"
          class="navigation-item"
          routerLinkActive="active">
          <ui-inline-svg class="navigation-item-icon" [name]="'file-text'"></ui-inline-svg>
        </a>

        <a
          uiTooltip
          [tooltipText]="'MENU.CUSTOMERS' | transloco"
          [tooltipPosition]="'bottom'"
          [routerLink]="['customers']"
          class="navigation-item"
          routerLinkActive="active">
          <ui-inline-svg class="navigation-item-icon" [name]="'users'"></ui-inline-svg>
        </a>

        <a
          uiTooltip
          [tooltipText]="'MENU.SETTINGS' | transloco"
          [tooltipPosition]="'bottom'"
          [routerLink]="['settings']"
          class="navigation-item"
          routerLinkActive="active">
          <ui-inline-svg class="navigation-item-icon" [name]="'settings'"></ui-inline-svg>
        </a>
      </div>

      <div class="separator"></div>

      <div class="user">
        <ui-button-alert [open]="alertsOpen" (click)="alertsOpen = !alertsOpen"></ui-button-alert>

          <div
            class="user-avatar"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            [ngClass]="{ open: userMenuOpen }"
            (click)="triggerMenu($event, trigger)"
            >
            @if (user.image) {
              <img class="user-avatar-img" [src]="user.image" />
            } @else {
              <span class="user-avatar-initials">{{ (user.displayName || '') | initials }}</span>
            }
          </div>
        </div>

        <app-user-menu class="user-menu" [user]="user" [origin]="trigger" (menuClose)="onCloseMenu()" #userMenu></app-user-menu>
      </div>
    } @else {
    <app-ui-language [pagePosition]="'top'"></app-ui-language>
    }
  </div>
</div>
