import { Environment } from '@instaclause-webapp/shared-core';

export const environment: Environment = {
  production: false,
  appName: 'accountant',
  apiBaseUrl: 'https://accountant.dev.instaclause.com/api',
  apiAuthUrl: 'https://admin.dev.instaclause.com/api',
  docsBaseUrl: 'https://documents.dev.instaclause.com',
  version: '2.0.0-alpha.5',
  amplitudeApiKey: '7bae693fd5ed3c538e712460676614c7',
};
